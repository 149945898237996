@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i');
@import url('https://use.fontawesome.com/releases/v5.0.6/css/all.css');

/* Bootstrap theme overrides */

$theme-colors: (
    "openmeta-blue": rgb(6, 41, 89)
);

$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$enable-transitions: true;
$enable-rounded: true;

@import "bootstrap/bootstrap";

/* Sticky footer styles */

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: theme-color("light");
}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container {
  padding-top: 1rem;
}

.footer > .container {
  /*padding-right: 1rem;
  padding-left: 1rem;*/
}

.feature-card-image {
    border-radius: 10%/10%;
}

.nav-item.active {
    font-weight: bold;
}

div.openmeta-feature-row {
    padding-top: 1rem;
}

div.case-study-card {
    height: 15rem;
    overflow: hidden;
    text-shadow: 0px 2px 2px black;
}

div.case-study-card > div.card-img-overlay {
    top: initial;
}

#petCarousel > a.carousel-control-prev {
    color: black;
}

#petCarousel > a.carousel-control-next {
    color: black;
}

.release-download-button {
    white-space: normal;
}
